/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
// import auth from "@/auth/authService";
import store from "./store/store";
import { auth } from "@/auth/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login-as-customer",
      name: "login-as-customer",
      component: () => import("@/views/LoginAsCustomer.vue"),
      meta: {
        rule: "editor",
        // requiresAuth: true
      },
    },
    {
      // for customer portal outside user login
      path: "",
      component: () => import("@/layouts/CreateLogin/CreateLogin.vue"),
      children: [
        {
          path: "payment-request/:prId",
          name: "paymentRequestDetail",
          component: () =>
            import("@/views/new-portal/payment-request/PaymentRequestDetail.vue"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          }
        },
        {
          path: "/:prId/payment-requests/:id/makePayment",
          name: "MakePayment",
          component: () => import("@/views/new-portal/payment-request/MakePayment.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Merchant" },
              { title: "Payments" },
              { title: "Make Payment", active: true },
            ],
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/:prId",
          name: "Authenticate",
          component: () => import("@/views/new-portal/Authenticate.vue"),
          meta: {
            rule: "editor",
          }
        },
        {
          path: "verify-code/:prId",
          name: "VerifyCode",
          component: () => import("@/views/new-portal/VerifyCode.vue"),
          meta: {
            rule: "editor"
          }
        },
      ],
    },
    {
      path: "/pages/maintenance",
      name: "page-maintenance",
      component: () => import("@/views/new-portal/Maintenance.vue"),
      meta: {
        rule: "editor",
      },
    },
    {
      path: "/pages/error-404",
      name: "page-error-404",
      component: () => import("@/views/new-portal/Error404.vue"),
      meta: {
        rule: "editor",
      },
    },
  ],
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || process.env.VUE_APP_NAME;
});

//this method is calling in each router navigate
//commented cause of reset-password link doesn't required to auth
router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    auth(to, from, next);
  } else {
    return next();
  }
});

export default router;
