/*=========================================================================================
  File Name: moduleCustomFieldGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  getCustomerPaymentMethods: state => {
    return state.customerPaymentMethods;
  },
}
