import axios from "../../../axios/index.js";

const apiAdminUrl = "/admin/";

export default {
  login(email, pwd) {
    return axios.post(apiAdminUrl + "auth/login", {
      email: email,
      password: pwd
    });
  },
  partnerLogin(email, pwd) {
    return axios.post("/auth/login", { email: email, password: pwd });
  },
  LoginAs(payload) {
    return axios.post(
      "/auth/login-as",
      { partnerId: payload.partnerId },
      payload.config
    );
  },
  LoginAsCustomer(payload) {
    return axios.post(
      "/auth/login-as-customer",
      { customerId: payload.customerId},
      payload.config
    );
  },
  refreshToken() {
    return axios.post("auth/refreshToken", {
      refreshToken: JSON.stringify(localStorage.getItem("refreshToKen"))
    });
  },
  checkTokenExpiry(token) {
    return axios.post("checkTokenExpiry", { token });
  },
  resetPassword(password, token) {
    return axios.post("/auth/resetPassword", {
      password: password,
      token: token
    });
  },
  resetAdminPassword(password, token) {
    return axios.post(apiAdminUrl + "/auth/resetPassword", {
      password: password,
      token: token
    });
  },
  forgotPasswordAdmin(email) {
    return axios.post("/auth/forgotPassword", { email: email });
  },
  /**
   * resend verification link
   * @param email
   * @returns {*}
   */
  resendVerificationMailAdmin(email) {
    return axios.post("auth/resendVerificationAdmin", { email: email });
  },
  changePassword(currentPassword, newPassword) {
    return axios.post(apiAdminUrl + "changePassword", {
      oldPassword: currentPassword,
      newPassword: newPassword
    });
  },

  checkResetTokenExpiry(token) {
    return axios.get(`auth/isResetTokenExpired?token=${token}`);
  },
  verifyMail(token) {
    return axios.get(`auth/verify/${token}`);
  },
  logout() {
    return axios.post(apiAdminUrl + "logout");
  },
  customerRefreshToken() {
    return axios.post("customers/refreshToken", {
      refreshToken: JSON.stringify(localStorage.getItem("refreshToken"))
    });
  }
};
