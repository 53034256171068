import axios from "@/axios";

export default {
  fetchAllLegals({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get("/admin/legals/"+params).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },
};
