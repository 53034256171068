import state from "./moduleLegalState.js";
import mutations from "./moduleLegalMutations.js";
import actions from "./moduleLegalActions.js";
import getters from "./moduleLegalGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
