import axios from "@/axios";
import httpStatusCode from "http-status-codes";
import Vue from "vue";
import VueCookies from "vue-cookies";
import jwt from "../../http/requests/auth/jwt";
Vue.use(VueCookies);

export default {
  sendCustomerOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/sendOtpCode", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  submitPaymentRequestId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/verifyPaymentRequestId", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMerchantLogo({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/getMerchantLogo/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  submitOtpAndLogin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/submitOtp", data)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            localStorage.setItem(
              "customer",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem(
              "accessToken",
              response.data.data.tokens.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.tokens.refreshToken
            );
            localStorage.setItem(
              "tokenExpiry",
              response.data.data.tokens.accessTokenExpiresIn
            );
            localStorage.setItem("loggedIn", "true");

            commit("UPDATE_CUSTOMER_INFO", response.data.data, { root: true });
            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  loginAsCustomer({ commit, state }, payload) {

    return new Promise((resolve, reject) => {
      jwt
        .LoginAsCustomer(payload)
        .then(response => {

          if (response.data.data) {
            localStorage.setItem("customer", JSON.stringify(response.data.data));

            // Set accessToken and refresh token
            localStorage.setItem(
              "accessToken",
              response.data.data.token.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.token.refreshToken
            );
            // var date = new Date().getTime() + 60 * 60 * 24 * 1000;
            localStorage.setItem(
              "tokenExpiry",
              response.data.data.token.accessTokenExpiresIn
            );
            localStorage.setItem("loggedIn", "true");

            // Update user details


            commit("UPDATE_USER_INFO", response.data.data, { root: true });

            // Set bearer token in axios

            commit("SET_BEARER", response.data.data.token.accessToken);
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
          // reject(error)
        });
    });
  },

  updateCustomerPaymentMethod({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/updatePaymentMethod", data)
        .then(res => {
          commit("SET_CUSTOMER_PAYMENT_METHODS", res.data.data);
          resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  verifyPRDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/verify-pr-details", data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  verifyAccountOtp({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/verify-account-otp", data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  verifyAccountEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/verify-account-email/${data.token}`)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  verifyAccountUpdatedEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/verify-account-email-update/${data.token}`)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  updateNewPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/create-password`, data)
        .then(response => {
          localStorage.setItem("customer", JSON.stringify(response.data.data));
          localStorage.setItem("accessToken", response.data.data.tokens.accessToken);
          localStorage.setItem("refreshToken", response.data.data.tokens.refreshToken);
          localStorage.setItem("tokenExpiry", response.data.data.tokens.accessTokenExpiresIn);
          localStorage.setItem("loggedIn", "true");

          commit("UPDATE_CUSTOMER_INFO", response.data.data, { root: true });
          commit("SET_BEARER", response.data.data.accessToken);

          resolve(response);
        })
        .catch(err => reject(err));
    });
  },
  resendCustomerCreateOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-customer-create-otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  resendCustomerCreateEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-customer-create-email`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/login`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  submitOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/otp`, data)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            localStorage.setItem(
              "customer",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem(
              "accessToken",
              response.data.data.tokens.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.tokens.refreshToken
            );
            localStorage.setItem(
              "tokenExpiry",
              response.data.data.tokens.accessTokenExpiresIn
            );
            localStorage.setItem("loggedIn", "true");

            commit("UPDATE_CUSTOMER_INFO", response.data.data, { root: true });
            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(err => reject(err));
    });
  },
  resendOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  forgotPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/forgot-password`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  verifyResetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/verify-reset-password/${data.token}`)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/reset-password`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  updateProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`customers/update-profile/${payload.partnerId}`, payload.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  changePassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`customers/change-password/${payload.partnerId}`, payload.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateProfileImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/update-profile-image`, payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePhoneOtpVerification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/verify-otp-for-phone-update`, payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resendUpdatePhoneOtpVerification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-otp-for-phone-update`, payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  /*payment request detail*/
  paymentRequestDetailByPRID({ commit}, requestId){
    return new Promise((resolve, reject) => {
      axios
        .get(`customers/payment-request-detail/${requestId}`)
        .then(res => {
          commit("SET_CUSTOMER_PAYMENT_METHODS", res.data.data.customerPaymentMethods);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getQuotationDataForPayLater({ commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .post("/calculation", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  fetchTermsAndConditions({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`customers/terms-and-conditions`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateDefaultPaymentMethod({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch(`customers/setDefaultPaymentMethod/${data._id}`, data.data).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex);
      })
    })
  }
};
