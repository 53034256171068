import axios from "@/axios";

export default {
  fetchMerchantDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/merchantHeaderDetails/" + id)
        .then(res => {
          if (res.data.data) {
            commit("SET_CUSTOMER_MERCHANT", res.data.data);
            localStorage.setItem("merchant", JSON.stringify(res.data.data));
          }

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
