/*=========================================================================================
  File Name: moduleCustomFieldMutations.js
  Description: Custom Field Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "../../http/axios/index.js";

export default {
  SET_CUSTOMER_MERCHANT(state, data) {
    state.merchant = {
      ...data.companyHeader
    };
  },
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  }
};
