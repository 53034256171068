/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import FeatherIcon from "./components/FeatherIcon.vue";


Vue.component(FeatherIcon.name, FeatherIcon);


// v-select component
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement("feather-icon", {
        props: {
          icon: "XIcon",
          svgClasses: "w-4 h-4 mt-1"
        }
      })
  },
  OpenIndicator: {
    render: createElement =>
      createElement("feather-icon", {
        props: {
          icon: "ChevronDownIcon",
          svgClasses: "w-5 h-5"
        }
      })
  }
});

Vue.component(vSelect);
