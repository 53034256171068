/*=========================================================================================
  File Name: moduleCustomFieldMutations.js
  Description: Custom Field Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "../../http/axios/index.js";

export default {
  SET_CUSTOMER_PARTNER(state, data) {
    state.partner = {
      partnerName: data.companyName,
      logo: data.profileImage,
      abn: data.abn,
      address: data.address,
      phoneNumber: data.phoneNumber,
      email: data.companyEmail,
      link: data.link
    };
  },
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  },
  SET_CUSTOMER_PAYMENT_METHODS(state, payload) {
    state.customerPaymentMethods = payload;
  },
};
