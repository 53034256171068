import axios from "@/axios.js";

export default {
  storePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests", data.obj, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },

  fetchPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },
  fetchPaymentRequestDetailByPRId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/prId/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },
  sendCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests/sendCodeToCustomer", data)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },
  verifyCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests/verifyCustomerCode", data)
        .then(res => {
          // Set accessToken and refresh token
          localStorage.setItem("accessToken", res.data.data.token.accessToken);
          localStorage.setItem("refreshToken", res.data.data.token.refreshToken);
          localStorage.setItem("tokenExpiry", res.data.data.token.accessTokenExpiresIn);
          //TODO: NEED TO UNCOMMENT AFTER 1.24 IS RELEASED
          // localStorage.setItem("chatbotHash", res.data.data.token.chatbotHash);
          localStorage.setItem("loggedIn", "true");

          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },
  fetchCustomerPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequests/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },
  fetchCustomerPaymentRequestDetailWithPartnerById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequestDetails/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },
  removeFileFromPaymentRequestByIdAndFileId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `paymentRequests/${data.id}/file/${data.fileId}?fileName=${data.fileName}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },
  cancelPaymentRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put("paymentRequests/cancel/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },
  resendPaymentRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put("paymentRequests/resend/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },

  makePayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/paymentRequests/make-payment", payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          return reject(ex);
        });
    });
  },

  saveProductFieldInPr({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/paymentRequests/saveProductFieldInPr", payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },

  saveCustomFieldInPr({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/paymentRequests/saveCustomFieldInPr", payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        });
    });
  },

  getQuotationDataForPayLater({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/calculation", data)
        .then(res => {
          return resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },

  updateTopUp({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("customers/paymentRequests/updateTopup", data).then(res => {
        resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  },
};

